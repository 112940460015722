:root {
  color-scheme: dark;
  --dark-gray: #221f20;
  --medium-gray: #bfbfbf;
  --white: #fff;
  --cyberpunk-bubblegum: #cf4673;
  --bland-mustard: #d9c84b;
  --minty-teal: #6ac4b0;
  --peoples-purple: #491d88;
  --relaxing-nectarine: #e07c31;
  --exciting-dark-blue: #143b86;

  --bland-mustard-transparent: #d9c84b6e;
  --cyberpunk-bubblegum-transparent: #cf46736e;
  --minty-teal-transparent: #6ac4b06e;
  --peoples-purple-transparent: #491d886e;
  --relaxing-nectarine-transparent: #e07c316e;
  --exciting-dark-blue-transparent: #143b866e;
}

main {
  margin: 0 auto;
}

ul {
  padding: 0;
  list-style-type: none;
}

a {
  color: var(--bland-mustard);
}

a:visited {
  color: var(--bland-mustard);
}

input[type="file"],
button {
  background-color: var(--bland-mustard);
  color: #000;
}

button {
  cursor: pointer;
  position: relative;

  &[aria-expanded="true"]:after {
    content: "-";
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 36px;
    height: 44px;
  }

  &[aria-expanded="false"]:after {
    content: "+";
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 36px;
    height: 44px;
  }
}
