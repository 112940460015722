.projects-page {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 16px;

  h2 {
    text-align: center;
  }
}

.projects-list-controls {
  background-color: var(--bland-mustard-transparent);
  padding: 16px;
}

.import-project-container {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.projects-list {
  text-align: center;
  margin-bottom: 64px;
  li {
    margin: 16px;
  }
  .projects-list-project {
    font-size: 24px;
  }
}

.projects-list-accordion {
  h2 {
    margin: 0;
  }
}

.new-project-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.projects-page-accordion {
  width: 100%;
  background: var(--relaxing-nectarine-transparent);
  padding: 8px;
  box-sizing: border-box;
  margin-bottom: 16px;
  h3 {
    margin: 0;

    button {
      width: 100%;
      cursor: pointer;
      margin: 0;
      color: white;
      background-color: transparent;
      border-bottom: 4px solid white;
      border-radius: 0;
    }
  }
}

.project-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
