.project-research-tools {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 16px;
  padding: 16px;
  border-bottom: 1px solid white;
}

.project-research-data {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 16px;
  border-bottom: 1px solid white;
}

.project-compounds,
.project-reactions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 45%;

  h4 {
    margin-bottom: 0;
    border-bottom: 1px solid white;
    padding-bottom: 16px;
  }
}

.projects-reactions-list {
  &>li {
    padding: 4px;
    margin-bottom: 4px;
    border: 1px solid white;
  }
}

.project-reaction-accordion {
  button {
    width: 100%;
  }
}

.reaction-accordion-content {
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  ul {
    padding-bottom: 12px;
  }
}

.new-reaction-input-output-selection {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 16px;
}

.new-reaction-form {
  border-top: 1px solid white;
  padding-top: 16px;
}

.reaction-inputs-list,
.reaction-outputs-list {
  text-align: left;
}

.new-reaction-name-selection {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}