.project-compounds {
  ul {
    text-align: left;
  }
  li {
    margin: 8px;
    padding-left: 8px;
    border-left: 1px solid #ccc;
  }
}

.compound-forms-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
  border-top: 1px solid white;
  padding-top: 16px;
}

.new-compound-form,
.add-compound-form {
  display: flex;
  flex-direction: column;
  width: 45%;
}

.compound-forms-divider {
  border-right: 1px solid white;
}
