.application-header {
  border-bottom: 1px solid var(--bland-mustard);
}

.vinni-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 8px;
  height: 80px;
}

.brand-container.brand-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--bland-mustard-transparent);

  .title-font {
    font-size: 32px;
  }

  a {
    display: block;
    color: #000;
    text-decoration: none;
    border-bottom: 5px solid #000;
    padding: 0 16px 0 16px;
    margin-left: -1px;
    margin-top: -2px;
  }
}

.logo-container {
  width: 70px;

  img {
    max-width: 100%;
  }
}

.vinni-counter-spacer {
  width: 90px;
  margin-right: 32px;
}

.speech-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.speech-bubble {
  position: relative;
  max-width: 200px;
  margin: 0;
  font-size: 16px;
  padding: 8px;
  border-radius: 16px;
  background-color: var(--bland-mustard);
  color: black;

  &:after {
    content: "";
    position: absolute;
    left: 100%;
    top: 50%;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 30px solid var(--bland-mustard);
  }
}

.vinni-container {
  position: relative;
  justify-self: end;
  width: 90px;
  margin-left: 32px;
}

.vinni-background {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  z-index: -1;
}

.vinni-head {
  position: absolute;
  width: 100%;
  height: 66%;
  left: 2px;
  top: 18px;
}

.vinni-jaw {
  position: absolute;
  width: 100%;
  height: 66%;
  left: 0;
  transform: rotate(0deg);
  transition: transform 0.2s ease;
  z-index: -1;
  top: 19px;
}

.vinni-jaw.talking {
  animation-name: example;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
}

@keyframes example {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(-4deg);
  }

  100% {
    transform: rotate(0deg);
  }
}
