.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  place-items: center;
  overflow-y: auto;
  background: rgba(0 0 0 / 0.5);
}

.dialog-content {
  position: fixed;
  top: 8px;
  left: 8px;
  right: 8px;
  bottom: 8px;
  display: flex;
  flex-direction: column;
  padding: 8px 8px 8px 8px;
  border-radius: 8px;
  background-color: black;
  border: 2px solid white;
}

.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;

  h2 {
    margin: 0;
  }
  button {
    margin: 0;
    color: #fff;
    background-color: transparent;
    font-size: 32px;
    line-height: 8px;
  }
}

.dialog-body {
  width: 100%;
  flex-grow: 1;
}

.dialog-iframe {
  width: 100%;
  height: 100%;
}
