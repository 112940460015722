.project-recipes {
  display: flex;
  li {
    margin-bottom: 16px;
  }
  border-bottom: 1px solid white;
}

.open-recipe-button {
  font-size: 16px;
}
